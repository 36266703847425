<template>
    <v-card max-width="1200" class="mx-auto "  >     

      <v-layout>                
        <AppBar_Admin />
        <v-main>
          <v-container fluid>
            <v-data-table                          
                :items="this.servers"
                :headers="this.serverheaders"
                item-value="Id"
                select-strategy="single"                
                return-object                   
                single-expand
                :search="search"
                show-expand
                @update:expanded="getSeverInfo"
                >
                <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Servers</v-toolbar-title>
                    <v-text-field
                    v-model="search"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                    ></v-text-field>
                </v-toolbar>
                </template>
                <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                    <v-card color="surface-variant" class="mt-5 mb-5">
                        <template v-slot:title>
                            Server: {{ item.Name }}
                        </template>                        
                        <template v-slot:actions>
                            <v-btn  class="ms-4 text-white" color="blue-darken-4" text="Server Users"
                            rounded="0"
                            variant="flat" @click="dialog_users = true"></v-btn>
                            <v-btn  class="ms-4 text-white" color="blue-darken-4" text="Server Sessions"
                            rounded="0"
                            variant="flat" @click="dialog_sessions = true"></v-btn>
                           
                        </template>
                    </v-card>                  
                    </td>
                </tr>
                </template>
            </v-data-table>      
            <v-dialog v-model="dialog_users" max-width="1600">
                <v-card prepend-icon="mdi-account" title="Server Users">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <h3>{{selected_server.Name}}</h3>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col >
                        <v-data-table                          
                          :items="this.selected_server.server_users"      
                          :headers="this.server_user_headers"       
                          v-model="selected_users"            
                          item-value="Id"                                               
                          :search="server_users_search"
                          return-object
                          show-select>
                          <template v-slot:top>
                          <v-toolbar flat>
                              <v-toolbar-title>Servers</v-toolbar-title>
                              <v-text-field
                              v-model="server_users_search"
                              label="Search"
                              prepend-inner-icon="mdi-magnify"
                              variant="outlined"
                              hide-details
                              single-line
                              ></v-text-field>
                          </v-toolbar>
                          </template>
                            <template v-slot:[`item.LastActivityDate`]="{ item }">
                                <span>{{ FormatDateFromISOString(item.LastActivityDate) }}</span>
                            </template>
                            <template v-slot:[`item.LastLoginDate`]="{ item }">
                                <span>{{ FormatDateFromISOString(item.LastLoginDate) }}</span>
                            </template>
                        </v-data-table>
                      </v-col>                                 
                    </v-row>                        
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text="Close"  variant="plain" @click="dialog_users = false">Close</v-btn>                    
                    <v-btn  text="Close"  variant="plain" @click="delSelectedUsers()">Delete Selected</v-btn>                    
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog_sessions" max-width="1600">
                <v-card prepend-icon="mdi-account" title="Server Sessions">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <h3>{{selected_server.Name}} ({{selected_server.server_sessions==undefined? "0": selected_server.server_sessions.length}})</h3>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col >
                        <v-data-table                          
                          :items="this.selected_server.server_sessions" 
                          :headers="this.server_session_headers"                                                            
                          item-value="Id"    
                          v-model="selected_sessions"                                           
                          return-object
                          show-select>  
                          <template v-slot:[`item.LastActivityDate`]="{ item }">
                                <span>{{ FormatDateFromISOString(item.LastActivityDate) }}</span>
                            </template>                       
                        </v-data-table>
                      </v-col>                                 
                    </v-row>                        
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text="Close"  variant="plain" @click="dialog_sessions = false">Close</v-btn>     
                    <v-btn  text="Close"  variant="primary" @click="delSelectedSessions()">Delete Sessions</v-btn>                 
                    
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../../stores/user'
import AppBar_Admin from '../../components/AppBar_Admin.vue'
import axios from "axios";



export default {
  data: () => ({
      visible: false,
      search: '',
      server_users_search: '',
      
      password: "",
      expanded: [],      
      dialog_users:false,
      dialog_sessions: false,
      selected_users:[],
      selected_server:[],
      servers:[],
      serverheaders: [
          {
            title: 'Id',
            align: 'start',            
            sortable: false,
            key: 'Id',
          },
          { title: 'Name', key: 'Name' },
          { title: 'URL', key: 'API_ENDPOINT' },
          { title: 'API_KEY', key: 'API_KEY' },
          { title: 'Total Users', key: 'Total_Users' },
      ],
      server_user_headers: [
          {
            title: 'Id',
            align: 'start',            
            sortable: false,
            key: 'Id',
          },
          { title: 'Name', key: 'Name' },
          { title: 'Disabled', key: 'Policy.IsDisabled' },          
          { title: 'Admin', key: 'Policy.IsAdministrator' },
          { title: 'Last Activity', key: 'LastActivityDate' },
          { title: 'Last Login', key: 'LastLoginDate' }          
      ],
      selected_sessions:[],
      server_session_headers: [
          {
            title: 'Id',
            align: 'start',            
            sortable: false,
            key: 'Id',
          },
          { title: 'Name', key: 'UserName' },
          { title: 'Now Playing', key: 'NowPlayingItem.Name' },          
          { title: 'RemoteEndPoint', key: 'RemoteEndPoint' },
          { title: 'Last Activity', key: 'LastActivityDate' },
          { title: 'DeviceName', key: 'DeviceName' }          
      ]
    }),
  setup() {
    const user = useUserStore();        
    
    return{user}

  },  
  computed: {

  },
  components: {
    AppBar_Admin
    },
    mounted(){
        this.getServers();
    },
    methods:{
    FormatDateFromISOString(isoString) {
        if(isoString== undefined)
        return "N/A";

        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    delSelectedSessions()
    {
        let server  = {
            Id: this.selected_server.Id,
            API_ENDPOINT: this.selected_server.API_ENDPOINT,
            API_KEY: this.selected_server.API_KEY
        }
        let data = {
            Server: server,
            Sessions: this.selected_sessions
        }
        console.log(data);
        axios.post("https://mediatrunk.net/jfin.tv/admin/delete_session.php",data)
        .then(response => {
            console.log(response.data);
            this.UpdateServerUsers();
                            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });         
    },
    delSelectedUsers()
    {
        let server  = {
            Id: this.selected_server.Id,
            API_ENDPOINT: this.selected_server.API_ENDPOINT,
            API_KEY: this.selected_server.API_KEY
        }
        let data = {
            Server: server,
            Users: this.selected_users
        }
        axios.post("https://mediatrunk.net/jfin.tv/admin/delete_user.php",data)
        .then(response => {
            console.log(response.data);
            this.UpdateServerUsers();
                            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });       
    },
    UpdateSeverSessions()
    {
        let data = {
            Id:this.selected_server.Id
        }
        axios.post("https://mediatrunk.net/jfin.tv/admin/get_server_info.php",data)
        .then(response => {
            console.log(response.data);
            this.selected_server.server_sessions = response.data.server_sessions;
                            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },
    SelectServer(item)
    {
      this.sel_user = item;
      this.dialog_server = true;
    },
    UpdateServerUsers()
    {
        let data = {
            Id:this.selected_server.Id
        }
        axios.post("https://mediatrunk.net/jfin.tv/admin/get_server_info.php",data)
        .then(response => {
            console.log(response.data);
            this.selected_server.server_users = response.data.server_users;
                            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },   
    getServers()
    {                      
        axios.post("https://mediatrunk.net/jfin.tv/admin/servers.php")
        .then(response => {
            this.servers = response.data.servers;  
                            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },
    getSeverInfo(item)
    {   
        if(item[0]!=undefined)             
        {
            this.selected_server = item[0];
            let data = {
                Id:this.selected_server.Id
            }
            axios.post("https://mediatrunk.net/jfin.tv/admin/get_server_info.php",data)
            .then(response => {
                console.log(response.data);
                this.selected_server.server_users = response.data.server_users;
                this.selected_server.server_sessions = response.data.server_sessions;
                                
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
        }
        
    }
  }

}
</script>