<template>
    <v-card max-width="1200" class="mx-auto "  >      
      <v-layout>                
        <AppBar />
        <v-main>
          <v-container fluid>
            <h2>Hello {{ user.name }}</h2>
            <p>Here you can manage your account</p>
            <h2></h2>
         
            <v-card
                 title="Account Status" 
                 color="teal-lighten-1"  
                 class="text-blue-grey-darken-4"                            
                >
                <div class="pa-5 text-grey-darken-4 font-weight-bold ">
                  <p>Renewed {{ user.last_payment }}</p>
                  <p>Next Renewal:  {{ user.expiry_date }}</p>
                  <v-btn to="/billing" color="teal-darken-4" class="float-right mb-2">Billing</v-btn>
                </div>
          </v-card>

            <h2>News</h2>
            <v-row dense>
              <v-col
                v-for="(item) in this.News" :key="item.id"                
                cols="12"
              >
                <v-card
                  :title="`${item.title}`"
                  :subtitle="`Posted: ${item.news_date} - Admin`"
                  :text="`${item.text}`"
                ></v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../stores/user'
import AppBar from '../components/AppBar.vue'
import axios from "axios";

export default {
  data: () => ({
      News: [],
    }),
  setup() {
    const user = useUserStore();        
    return{user}
  },  
  components: {
    AppBar
    },
    mounted(){
      this.GetNews();
    },
    methods:{
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    GetNews()
    {
      let self = this;
      self.doneRestorePwd = false;
      let NewsData = {
        action:"GET",        
            }                        
            axios.post("https://mediatrunk.net/jfin.tv/news.php", NewsData)
            .then(response => {                   
                self.News = response.data.data;                        
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);                                
            });
    },
  }

}
</script>