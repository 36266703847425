<template>
    <v-card max-width="1600" class="mx-auto "  >     

      <v-layout>                
        <AppBar_Admin />
        <v-main>
          <v-container fluid>
          <template>
            <div class="pa-4 text-center">
              <v-dialog v-model="dialog" max-width="600">
                <v-card prepend-icon="mdi-account" title="User Profile">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="BillingID" required v-model="sel_user.billing_id"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Role" required v-model="sel_user.role"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Banned" required v-model="sel_user.banned"></v-text-field>
                      </v-col>                    
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="First name" required v-model="sel_user.name"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Surname" v-model="sel_user.surname"></v-text-field>
                      </v-col>
                      <v-col  cols="12" md="4" sm="6">
                        <v-text-field label="Email*" v-model="sel_user.mail"></v-text-field>
                      </v-col>
                      <v-col  cols="12" md="4" sm="6">
                        <v-text-field label="Last Payment" v-model="sel_user.last_payment"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Access" required v-model="sel_user.access"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Invites Available" required v-model="sel_user.invites_available"></v-text-field>
                      </v-col>                                            
                    </v-row>
                    <v-row dense>
                      <label>New password</label>
                    </v-row>
                    <v-row dense>                      
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Password*" required v-model="sel_user.password"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-btn text="Generate Password"  @click="sel_user.password = GeneratePassword(8)" ></v-btn>
                      </v-col>
                    </v-row>                    
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text="Close"  variant="plain" @click="dialog = false"></v-btn>
                    <v-btn color="primary" text="Save" variant="tonal"  @click="SaveDetails()"></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog_server" max-width="1600">
                <v-card prepend-icon="mdi-account" title="User Profile">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <h3>Select a server</h3>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col >
                        <v-data-table                          
                          :items="this.servers"
                          :headers="this.serverheaders"
                          item-value="Id"
                          select-strategy="single"
                          v-model="selected_server"
                          return-object
                          show-select
                        ></v-data-table>
                      </v-col>                                 
                    </v-row>             
                    <v-row dense>
                      <label>Account password</label>
                    </v-row>
                    <v-row dense>                      
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="Password*" required v-model="selected_server_user_password"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-btn text="Generate Password"  @click="selected_server_user_password = GeneratePassword(8)" ></v-btn>
                      </v-col>
                    </v-row>              
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text="Close"  variant="plain" @click="dialog_server = false"></v-btn>
                    <v-btn color="primary" text="Save" variant="tonal"  @click="ChangeUserServer()"></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>

            <v-data-table
                :search="search"
                v-model:expanded="expanded"
                :headers="this.userHeaders"
                :items="this.users"
                item-value="id"
                single-expand
                show-expand
                @update:expanded="loadDetails"
            >
            
                <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Users</v-toolbar-title>
                    <v-text-field
                    v-model="search"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                    ></v-text-field>
                </v-toolbar>
                
                </template>
                <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                    <v-card color="surface-variant" class="mt-5 mb-5">
                        <template v-slot:title>
                          Customer Id: {{ this.sel_user.id }}                  
                        </template>

                        <template v-slot:subtitle>
                         Customer saved cards                                 
                        </template>

                        <template v-slot:text>
                          <div :v-if="item.cards != null">
                          <div v-for="card in item.cards" :key="card.id">                            
                            <p>Id: {{ card.id }} </p>
                            <p>Brand: {{ card.card_brand }}</p>
                            <p>Last 4: {{ card.last_4 }}</p>
                            <v-btn  class="ms-4 text-white" color="blue-darken-4" :text="'Delete '+card.card_brand +'(' + card.last_4+')'"
                            rounded="0"
                            variant="flat" @click="DeleteCard(card,item)" ></v-btn>
                          </div>
                        </div>

                        </template>
                        <template v-slot:actions>
                            <v-btn  class="ms-4 text-white" color="blue-darken-4" text="Delete Customer"
                            rounded="0"
                            variant="flat" @click="DeleteCustomer(item)"></v-btn>
                            
                        </template>
                    </v-card>                  
                    </td>
                </tr>
                </template>
            </v-data-table>   

            
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
  </template>

<script>
import { useUserStore } from '../../stores/user'
import AppBar_Admin from '../../components/AppBar_Admin.vue'
import axios from "axios";
import router from '../../router'


export default {
  data: () => ({
      visible: false,
      dialog: false,
      dialog_server: false,
      sel_user: null,
      selected_server:[],
      selected_server_user_password:"",
      search: '',
      password: "",
      expanded: [],
      new_password: "",
      users: [],
        userHeaders: [
              
          { title: 'E-mail', key: 'email_address' },
          { title: 'Phone', key: "phone_number"},
          { title: 'Customer Id	', key: 'id' },          
          { title: 'Name', key: 'given_name' },
          { title: 'Lastname', key: 'family_name' },
          {title: 'Created At', key: 'created_at'},
          {title: 'Updated At', key: 'updated_at'},
                    
          { title: '', key: 'data-table-expand' },
        ],
        servers:[],
      serverheaders: [
          {
            title: 'Id',
            align: 'start',            
            sortable: false,
            key: 'Id',
          },
          { title: 'Name', key: 'Name' },
          { title: 'URL', key: 'API_ENDPOINT' },
          { title: 'API_KEY', key: 'API_KEY' },
          { title: 'Total Users', key: 'Total_Users' },
      ]
    }),
  setup() {
    const user = useUserStore();        
    
    return{user}

  },  
  computed: {
    isUserDisabled()
    {
      if(this.sel_user.jfin_data != undefined)
      return this.sel_user.jfin_data.Policy.IsDisabled ? "Yes": "No";
      else return "N/A";
    },
    LastLogin()
    {
      if(this.sel_user.jfin_data != undefined)
      return this.FormatDateFromISOString(this.sel_user.jfin_data.LastLoginDate);
      else return "N/A";
    }
  },
  components: {
    AppBar_Admin
    },
    mounted(){
        this.getUsers();
        this.getServers();
    },
    methods:{
    ChangeUserServer()
    {
      console.log(this.selected_server);
    if(this.selected_server_user_password!="" && this.sel_user != undefined && this.selected_server != undefined)
    {
      let UserData = {
                User:this.sel_user,            
                Server: this.selected_server,
                User_Password: this.selected_server_user_password
            }                        
            axios.post("https://mediatrunk.net/jfin.tv/admin/set_user_server.php", UserData)
            .then(response => {   
              console.log(response.data);
              alert(response.data.reason);           
                this.dialog = false;
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
    }else
    alert("Something is missing, did you fill in new password? Is a server selected?");
     
    },
    FormatDateFromISOString(isoString) {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    },

    GeneratePassword(passwordLength) {
      var numberChars = "0123456789";
      var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var lowerChars = "abcdefghijklmnopqrstuvwxyz";
      var allChars = numberChars + upperChars + lowerChars;
      var randPasswordArray = Array(passwordLength);
      randPasswordArray[0] = numberChars;
      randPasswordArray[1] = upperChars;
      randPasswordArray[2] = lowerChars;
      randPasswordArray = randPasswordArray.fill(allChars, 3);
      return this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
      },
      shuffleArray(array) {
          for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
          }
          return array;
        },
  
    SaveDetails()
    {
                       
            axios.post("https://mediatrunk.net/jfin.tv/admin/save_user.php", this.sel_user)
            .then(response => {     
              alert(response.data.reason);              
                this.dialog = false;
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
            });
    },
    SelectServer(item)
    {
      this.sel_user = item;
      this.dialog_server = true;
    },
    Impersonate(item)
    {
     
      console.log(item);
      let user_servers = [];
      let UserData = {
                UserId:item.user_id,            
            }                        
            axios.post("https://mediatrunk.net/jfin.tv/admin/get_user_servers.php", UserData)
            .then(response => {
                
                user_servers = response.data.servers; 
                console.log(user_servers);
                const user = useUserStore(); 
                user.$patch({email: item.mail,
                                 name: item.name,
                                 surname: item.surname,
                                 phone:item.phone,
                                 country:item.country,
                                 city:item.city,
                                 address: item.address,
                                 province: item.province,
                                 postal:item.postal,
                                 last_payment:  item.last_payment,
                                 expiry_date: item.expiry_date,
                                 id:item.user_id,                                 
                                 billing_id: item.billing_id,
                                 cost: item.cost,
                                 role: item.role,
                                 token: item.token,
                                 servers:user_servers});                                      
                                 router.push('/');                                     
            })
            .catch(error => {
                this.errorMessage = error.message;
                
                console.error("There was an error!", error);
            });


      


    },
    DeleteCard(card,customer)
    {
      let CardData = {
            card:card,
            customer:customer,            
        }                        
        axios.post("https://mediatrunk.net/jfin.tv/admin/del_card.php", CardData)
        .then(response => {
            console.log(response);                         
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },
    DeleteCustomer(customer)
    {
      let CustomerData = {            
            customer:customer,            
        }                        
        axios.post("https://mediatrunk.net/jfin.tv/admin/del_customer.php", CustomerData)
        .then(response => {
            console.log(response);                         
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },
    
    loadDetails(item)
    {      

      console.log(item);
      this.expanded.forEach((ex) => {
        if(ex == item)
        {
          this.users.forEach((u) => {
          if(u.id == item)
          {
            this.sel_user = u;
            console.log(this.sel_user);      
          }
        });
        }
      });
      
      
    },        
    Launch(URL){
      window.open(URL,'_blank');                    
    },
    getUsers()
    {
        let LoginData = {
            Email:this.user.email,
            Password:this.password,
            NewPassword: this.new_password
        }                        
        axios.post("https://mediatrunk.net/jfin.tv/admin/square_users.php", LoginData)
        .then(response => {
            this.users = response.data.users;     
            console.log(this.users)       ;              
            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    },
    getServers()
    {                      
        axios.post("https://mediatrunk.net/jfin.tv/admin/servers.php")
        .then(response => {
            this.servers = response.data.servers;  
                            
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
    }
  }

}
</script>