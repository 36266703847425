<template>
  <v-card max-width="1200" class="mx-auto "  >     

    <v-layout>                
      <AppBar_Admin />
      <v-main>
        
        
        <v-container fluid>          
          <h2>Admin - Overview</h2>
          <v-data-table class="mt-5"                         
              :items="this.news"
              :headers="this.newsheader"
              item-value="Id"
              select-strategy="single"                
              return-object>
              <template v-slot:top>
              <v-toolbar flat>
                  <v-toolbar-title>News</v-toolbar-title>
                  <v-text-field
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  variant="outlined"
                  hide-details
                  single-line
                  ></v-text-field>
                  <v-dialog v-model="dialog" max-width="800px">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="mb-2"
                      color="primary"
                      dark
                      v-bind="props"
                    >
                      New Item
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="8" sm="8">
                            <v-text-field
                              v-model="editedItem.title"
                              label="Title"
                            ></v-text-field>
                          </v-col>                        
                          <v-col cols="12" md="4" sm="4">
                            <v-text-field 
                              v-model="editedItem.news_date"
                              readonly
                              label="Date"
                            ></v-text-field>
                          </v-col>                          
                        </v-row>
                        <v-row>
                          <v-col >
                            <v-textarea label="Text" variant="outlined" v-model="editedItem.text"></v-textarea>                            
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue-darken-1" variant="text" @click="cancel">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue-darken-1" variant="text" @click="save">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Are you sure you want to delete this item?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
              </template>
              <template v-slot:expanded-row="{ columns, item }">
              <tr>
                  <td :colspan="columns.length">
                  <v-card color="surface-variant" class="mt-5 mb-5">                    
                      <template v-slot:title>
                            {{ item.title }}
                        </template>
            
                    
                           
                      <template v-slot:text>
                        <div>
                            <v-textfield label="Title" ></v-textfield>
                            <v-textarea label="Text" variant="outlined" :model-value="item.text"></v-textarea>
                          </div>
                        </template>                                 
                  </v-card>                  
                  </td>
              </tr>
              </template>
              <template #[`item.actions`]="{ item }">
                          <v-icon class="me-2" size="small" @click="editItem(item)" >mdi-pencil</v-icon>
                          <v-icon size="small" @click="deleteItem(item)">mdi-delete</v-icon>
                          
                        </template>
          </v-data-table>                         
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import { useUserStore } from '../../stores/user'
import AppBar_Admin from '../../components/AppBar_Admin.vue'
import axios from "axios";



export default {
data: () => ({
    dialog:false,     
    dialogDelete:false,   
    editedIndex: -1,
    editedItem: {
      id: 0,
      title: "",
      text: "",
      news_date: "",      
    },
    defaultItem: {
      id: null,
      title: "",
      text: "",
      news_date: "",      
    },
    news:[],
    newsheader: [
        {
          title: 'Id',
          align: 'start',            
          sortable: false,
          key: 'id',
        },
        { title: 'Title', key: 'title' },
        { title: 'Text', key: 'text' },
        { title: 'Date', key: 'news_date' },
        { title: 'Actions', key: 'actions', sortable: false }
    ],   
  }),
setup() {
  const user = useUserStore();        
  
  return{user}

},  
computed: {
  formTitle() {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
},
components: {
  AppBar_Admin
  },
  mounted(){
      this.getNews();
  },
  watch: {
      dialog(val) {
        val || this.cancel()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
    },
  methods:{
    cancel(){
      this.dialog = false;
      this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
    },
    closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
    },
    save() {
      let NewsData = {
        action:"POST", 
        Item: this.editedItem       
        }      
          axios.post("https://mediatrunk.net/jfin.tv/news.php", NewsData)
        .then(response => {                   
            console.log(response.data);
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);                                
        });  
        
        this.cancel();
        this.getNews();
    },
    editItem (item) {
        this.editedIndex = this.news.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

    deleteItem (item) {
      this.editedIndex = this.news.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true;      
    },
    deleteItemConfirm()
    {
      let NewsData = {
        action:"DELETE", 
        Item: this.editedItem       
        }      
          axios.post("https://mediatrunk.net/jfin.tv/news.php", NewsData)
        .then(response => {                   
            console.log(response.data);
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);                                
        });  
      this.closeDelete();
      this.getNews();
    },
  getNews()
  {                      
    let NewsData = {
        action:"GET",        
        }                        
    axios.post("https://mediatrunk.net/jfin.tv/news.php", NewsData)
    .then(response => {                   
        this.news = response.data.data;                        
    })
    .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);                                
    });  
  },
}
}
</script>